import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  ArrayInput,
  fetchEnd,
  fetchStart,
  FormDataConsumer,
  NumberField,
  NumberInput,
  REDUX_FORM_NAME,
  ReferenceInput,
  SelectInput,
  showNotification,
} from 'react-admin';
import compose from 'recompose/compose';
//import { DateInput } from 'react-admin-date-inputs';
import { connect } from 'react-redux';
import { clearFields as clearFieldsAction, getFormValues } from 'redux-form';
import { withStyles } from '@material-ui/core';
//import { Link } from 'react-router-dom';
import AutocompleteInput from '../../../../common/ui/AutocompleteInputStyled';
import TableFormIterator from '../../../../common/ui/tableFormIterator';
import {
  getDiscountPercent,
  getDiscountValue,
  getEstimatedTotalPriceExVat,
  getTotalPriceExVat,
} from '../../../../../services/calcOrdersFunc';
//import { convertUtcToLocal } from '../../../../../services/date';
import { CuttingSpecField, PackingSpecField } from './CustomerSpecFields';
import CurrencySign from '../../../../common/ui/CurrencySign';
import { ordersConstants } from '../../../../../constants/ordersConstants';
import resources from '../../../../../constants/resources';
//import formats from '../../../../../constants/formats';
//import { PRODUCTS_ROUTE } from '../../../../../constants/routes';
import numberDigits from '../../../../../constants/numberFormat';
import { roles } from '../../../../../constants/roles';
import validators from '../../../../../validators';
import { SalesOrdersPriceInfo } from '../PriceInfo';
import { apiProductPriceCalc } from '../../../../../api/productPriceCalc';
import { AllergensField } from './tableSpecialFields/AllergensField';
import {IngredientsField} from './tableSpecialFields/ingredientsField';
import {NutritionalField} from './tableSpecialFields/NutritionalField';

const MoneyTypography = ({ children }) => (
  <Fragment>
    {`${children} `}
    <CurrencySign />
  </Fragment>
);

MoneyTypography.propTypes = {
  children: PropTypes.string,
};

const styles = {
  linkButton: {
    '&:first-child': {
      paddingLeft: 0,
      textTransform: 'capitalize',
    },
  },
  arrayInput: {
    margin: 0,
  },
  autocomplete: {
    minHeight: 'initial',
  },
  inputMultiline: {
    overflow: 'hidden',
  },
  tableRow: {
    '& > td': {
      verticalAlign: 'bottom',
    },
  },
};

const sanitizeProps = ({
  fetchEndAction,
  fetchStartAction,
  transformedInitialValues,
  showNotificationAction,
  redirect,
  save,
  dirty,
  ...rest
}) => rest;

class CurrentOrderItems extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { formData, vatCodes } = this.props;
    return (
      (formData || {}).saleOrderItems !== (nextProps.formData || {}).saleOrderItems || vatCodes !== nextProps.vatCodes
    );
  }

  clearFields = (sources = []) => {
    const { changeValue, clearFields, isEdit } = this.props;

    if (isEdit) {
      sources.forEach(s => changeValue(s, null));
    } else {
      clearFields(sources);
    }
  };

  handleChangeProductId = (item, isEdit, getSource) => (e, productId) => {
    const {
      productItems,
      customers,
      changeValue,
      vatCodes,
      formData,
      fetchStartAction,
      showNotificationAction,
      fetchEndAction,
      permissions,
    } = this.props;
    const { disc = 0, weight, quantity } = item;

    const { customerId } = formData;
    const currentCustomer = customers[customerId] || {};
    const { discount, priceListId } = currentCustomer;

    const currentProduct = productItems[productId] || {};
    const {
      priceMethod = 0,
      vatcodeId,
      description,
      costPricePerUnit = 0,
      pricePerUnit = 0,
      priceListItems = [],
      orderMethod = 0,
    } = currentProduct;
    const vatCode = vatCodes[vatcodeId];

    // let currentCustomerProductPrices = [];
    // let currentCustomerSpecialPrices = [];

    fetchStartAction();
    // const promises = [
    //   dataProvider(GET_LIST, resources.PRODUCT_PRICE, {
    //     pagination: { page: 1, perPage: 1 },
    //     sort: { field: 'id', order: 'DESC' },
    //     filter: { customerId, productId: [productId] },
    //   }),
    // ];
    // if (priceListId) {
    //   promises.push(
    //     dataProvider(GET_LIST, resources.PRICE_LIST_ITEM, {
    //       pagination: { page: 1, perPage: 1 },
    //       sort: { field: 'id', order: 'DESC' },
    //       filter: { priceListId, productId },
    //     }),
    //   );
    // }

    let priceData;

    apiProductPriceCalc({ customerId, productId })
      .then(data => (priceData = data.result))

      // Promise.all(promises)
      //   .then(data => {
      //     const [customerPrice, priceListPrice, specialPrices] = data;
      //     if (specialPrices && specialPrices.data.length) {
      //       currentCustomerSpecialPrices = specialPrices.data;
      //     } else if (customerPrice && customerPrice.data.length) {
      //       currentCustomerProductPrices = customerPrice.data;
      //     } else if (priceListPrice && priceListPrice.data.length) {
      //       currentCustomerProductPrices = priceListPrice.data;
      //     }
      //   })
      .catch(error => {
        showNotificationAction(error.message, 'error');
      })
      .finally(() => {
        fetchEndAction();

        const currentPriceList = priceListItems.find(_ => _.priceListId === priceListId);

        let price = pricePerUnit;
        let method = priceMethod;
        let salesMethod = orderMethod;

        if (priceData) {
          price = priceData.pricePerUnit;
          method = priceData.priceMethod;
        } else if (currentPriceList) {
          price = currentPriceList.pricePerUnit;
          method = currentPriceList.priceMethod;
        }

        // if (currentCustomerSpecialPrices.length) {
        //   price = currentCustomerSpecialPrices[0].pricePerUnit;
        //   method = currentCustomerSpecialPrices[0].priceMethod;
        // } else if (currentCustomerProductPrices.length) {
        //   price = currentCustomerProductPrices[0].pricePerUnit;
        //   method = currentCustomerProductPrices[0].priceMethod;
        // } else if (currentPriceList) {
        //   price = currentPriceList.pricePerUnit;
        //   method = currentPriceList.priceMethod;
        // }

        let quantityValue = 0;
        switch (method) {
          case ordersConstants.quantityMethod:
            this.clearFields([getSource('weight')]);
            quantityValue = quantity || 0;
            break;
          case ordersConstants.weightMethod:
            this.clearFields([getSource('quantity')]);
            quantityValue = weight || 0;
            break;
          default:
            this.clearFields([getSource('quantity'), getSource('weight')]);
        }

        const totalPriceExVat = getTotalPriceExVat(method, pricePerUnit, discount || disc, quantityValue);
        const estimatedTotalPriceExVat = getEstimatedTotalPriceExVat(totalPriceExVat, vatCode);
        const discountValue = getDiscountValue(price, discount || disc);

        changeValue(getSource('priceMethod'), method);
        changeValue(getSource('productDescription'), description);
        changeValue(getSource('costPricePerUnit'), costPricePerUnit.toFixed(2));
        changeValue(getSource('pricePerUnit'), price.toFixed(2));
        changeValue(getSource('disc'), (+discount || +disc || 0).toFixed(2));
        changeValue(getSource('discPricePerUnit'), discountValue.toFixed(2));
        changeValue(getSource('totalPriceExVat'), totalPriceExVat.toFixed(2));
        changeValue(getSource('totalVatAmount'), estimatedTotalPriceExVat.toFixed(2));
        changeValue(getSource('packTypeId'), productItems[productId].metricId);
        changeValue(getSource('saleType'), salesMethod);

        if (currentProduct.b2BNotes && permissions === roles.CUSTOMER_ROLE) {
          window.alert(currentProduct.b2BNotes); // eslint-disable-line no-alert
        }
      });
  };

  handleChangeDiscountValue = (item, getSource) => (e, newDiscValue) => {
    const { productItems, changeValue, vatCodes } = this.props;
    const { priceMethod, pricePerUnit, weight, quantity, productId } = item;
    const { vatcodeId } = productItems[productId];
    const vatCode = vatCodes[vatcodeId];

    const newDiscPercent = getDiscountPercent(pricePerUnit, newDiscValue);
    const totalPriceExVat = getTotalPriceExVat(priceMethod, pricePerUnit, newDiscPercent, weight || quantity);
    const estimatedTotalPriceExVat = getEstimatedTotalPriceExVat(totalPriceExVat, vatCode);

    changeValue(getSource('disc'), newDiscPercent.toFixed(2));
    changeValue(getSource('totalPriceExVat'), totalPriceExVat.toFixed(2));
    changeValue(getSource('totalVatAmount'), estimatedTotalPriceExVat.toFixed(2));
  };

  handleChangeQuantity = (item, getSource) => (e, newQuantity) => {
    const { productItems, changeValue, vatCodes } = this.props;
    const { priceMethod, pricePerUnit, disc, productId } = item;
    const { vatcodeId } = productItems[productId];
    const vatCode = vatCodes[vatcodeId];

    const totalPriceExVat = getTotalPriceExVat(priceMethod, pricePerUnit, disc, newQuantity);
    const estimatedTotalPriceExVat = getEstimatedTotalPriceExVat(totalPriceExVat, vatCode);

    changeValue(getSource('totalPriceExVat'), totalPriceExVat.toFixed(2));
    changeValue(getSource('totalVatAmount'), estimatedTotalPriceExVat.toFixed(2));
  };

  required = (value, values, props) =>
    value ? undefined : props.translate('ra.validation.required', { _: 'ra.validation.required' });

  checkDisabledQuantityOrWeight = (scopedFormData, type) => {
    const currentProduct =
      scopedFormData.productId && this.props.productItems && this.props.productItems[scopedFormData.productId];

    if (!currentProduct) {
      return true;
    }

    if (type === 'quantity') {
      return (
        currentProduct.orderMethod === ordersConstants.weightMethod ||
        currentProduct.orderMethod === ordersConstants.typicalWeightMethod
      );
    } else {
      return currentProduct.orderMethod === ordersConstants.quantityMethod;
    }
  };

  render() {
    const {
      classes,
      isEdit,
      formProps,
      formProps: { record = {} },
      addItemValidation,
      permissions,
    } = this.props;
    const sanitizedFormProps = sanitizeProps(formProps);
    return (
      <ArrayInput source="saleOrderItems" label="" className={classes.arrayInput}>
        <TableFormIterator
          isNewDesign
          disableAdd={record.dispatched}
          disableRemove={record.dispatched}
          addItemValidation={addItemValidation}
          bodyRowClassName={classes.tableRow}
        >
          <FormDataConsumer label="Product Code">
            {({ formData, scopedFormData, getSource }) => (
              <ReferenceInput
                label=""
                source={getSource('productId')}
                reference={resources.PRODUCT}
                {...sanitizedFormProps}
                onChange={this.handleChangeProductId(scopedFormData, isEdit, getSource)}
                sort={{ field: 'productCode', order: 'ASC' }}
                validate={this.required}
                allowEmpty
              >
                {formData.dispatched ? (
                  <SelectInput optionText="productCode" disabled />
                ) : (
                  <AutocompleteInput optionText={item => item.productCode || item.productCode.trim() || ''} />
                )}
              </ReferenceInput>
            )}
          </FormDataConsumer>

          <FormDataConsumer label="Product">
            {({ formData, scopedFormData, getSource }) => (
              //scopedFormData.productDescription && (
              //{permissions && permissions === roles.USER_ROLE ? (
              <Fragment>
                <ReferenceInput
                  label=""
                  source={getSource('productId')}
                  reference={resources.PRODUCT}
                  {...sanitizedFormProps}
                  onChange={this.handleChangeProductId(scopedFormData, isEdit, getSource)}
                  sort={{ field: 'Description', order: 'ASC' }}
                  validate={this.required}
                  allowEmpty
                >
                  {formData.dispatched ? (
                    <SelectInput optionText="productDescription" disabled />
                  ) : (
                    <AutocompleteInput
                      optionText={item => (item.description && item.description.trim()) || ''}
                      options={{
                        multiline: true,
                        InputProps: {
                          classes: {
                            multiline: classes.autocomplete,
                            inputMultiline: classes.inputMultiline,
                          },
                        },
                      }}
                    />
                  )}
                </ReferenceInput>
              </Fragment>
            )
            //) : (
            //  )
            //}
            //)
            }
          </FormDataConsumer>

          <FormDataConsumer label="Quantity">
            {({ formData, scopedFormData, getSource }) => (
              <NumberInput
                label=""
                source={getSource('quantity')}
                disabled={
                  formData.dispatched ||
                  !scopedFormData.productId ||
                  this.checkDisabledQuantityOrWeight(scopedFormData, 'quantity')
                }
                onChange={this.handleChangeQuantity(scopedFormData, getSource)}
                validate={validators.INTEGER_POSITIVE}
              />
            )}
          </FormDataConsumer>

          <FormDataConsumer label="Weight">
            {({ formData, scopedFormData, getSource }) => (
              <NumberInput
                label=""
                source={getSource('weight')}
                disabled={
                  formData.dispatched ||
                  !scopedFormData.productId ||
                  this.checkDisabledQuantityOrWeight(scopedFormData, 'weight')
                }
                onChange={this.handleChangeQuantity(scopedFormData, getSource)}
              />
            )}
          </FormDataConsumer>

          <FormDataConsumer label="Units">
            {({ formData, getSource }) => (
              <ReferenceInput
                label=""
                source={getSource('packTypeId')}
                reference={resources.PRODUCT_PACK_TYPE}
                sort={{ field: 'description', order: 'ASC' }}
                {...sanitizedFormProps}
                allowEmpty
              >
                <SelectInput optionText="description" disabled={!!formData.dispatched} />
              </ReferenceInput>
            )}
          </FormDataConsumer>

          <FormDataConsumer label={<MoneyTypography>Price</MoneyTypography>}>
            {({ formData, scopedFormData, getSource }) =>
              scopedFormData.productId && (
                <SalesOrdersPriceInfo
                  isPrice
                  show={this.props.checkMethodDifference(scopedFormData)}
                  text={scopedFormData.discPricePerUnit}
                >
                  {permissions && permissions === roles.USER_ROLE ? (
                    <NumberInput
                      label=""
                      source={getSource('discPricePerUnit')}
                      disabled={
                        !!formData.dispatched ||
                        !scopedFormData.productId ||
                        (permissions && permissions === roles.CUSTOMER_ROLE)
                      }
                      onChange={this.handleChangeDiscountValue(scopedFormData, getSource)}
                    />
                  ) : (
                    <NumberField source={getSource('discPricePerUnit')} record={formData} options={numberDigits} />
                  )}
                </SalesOrdersPriceInfo>
              )
            }
          </FormDataConsumer>

          <FormDataConsumer label={<MoneyTypography>Total</MoneyTypography>}>
            {({ formData, scopedFormData, getSource }) => (
              <SalesOrdersPriceInfo show={this.props.checkMethodDifference(scopedFormData)}>
                <NumberField source={getSource('totalPriceExVat')} record={formData} options={numberDigits} />
              </SalesOrdersPriceInfo>
            )}
          </FormDataConsumer>

          {isEdit && (
            <FormDataConsumer label="Cutting Spec.">
              {({ formData, scopedFormData, getSource }) => (
                <CuttingSpecField
                  formData={formData}
                  source={getSource(null)}
                  scopedFormData={scopedFormData}
                  {...sanitizedFormProps}
                />
              )}
            </FormDataConsumer>
          )}

          {isEdit && (
            <FormDataConsumer label="Packing Spec.">
              {({ formData, scopedFormData, getSource }) => (
                <PackingSpecField
                  formData={formData}
                  source={getSource(null)}
                  scopedFormData={scopedFormData}
                  {...sanitizedFormProps}
                />
              )}
            </FormDataConsumer>
          )}

          {!isEdit && (
            <FormDataConsumer label="Ingredients">
              {({ scopedFormData }) => <IngredientsField model={this.props.productItems[scopedFormData.productId]} />}
            </FormDataConsumer>
          )}

          {!isEdit && (
              <FormDataConsumer label="Allergens">
                {({ scopedFormData }) => <AllergensField model={this.props.productItems[scopedFormData.productId]} />}
              </FormDataConsumer>
          )}

          {!isEdit && (
              <FormDataConsumer label="Nutritional Info">
                {({ scopedFormData }) => <NutritionalField model={this.props.productItems[scopedFormData.productId]} />}
              </FormDataConsumer>
          )}
        </TableFormIterator>
      </ArrayInput>
    );
  }
}

CurrentOrderItems.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  productItems: PropTypes.objectOf(PropTypes.object),
  formProps: PropTypes.instanceOf(Object),
  formData: PropTypes.instanceOf(Object),
  customers: PropTypes.instanceOf(Object),
  vatCodes: PropTypes.objectOf(PropTypes.object),
  changeValue: PropTypes.func,
  clearFields: PropTypes.func,
  isEdit: PropTypes.bool,
  addItemValidation: PropTypes.func,
  fetchEndAction: PropTypes.func.isRequired,
  fetchStartAction: PropTypes.func.isRequired,
  showNotificationAction: PropTypes.func.isRequired,
  permissions: PropTypes.string,
  checkMethodDifference: PropTypes.func,
};

const mapStateToProps = state => ({
  formData: getFormValues(REDUX_FORM_NAME)(state),
  customers: state.admin.resources[resources.CUSTOMER].data,
});

export default compose(
  connect(
    mapStateToProps,
    {
      clearFields: fields => clearFieldsAction(REDUX_FORM_NAME, false, false, ...fields),
      fetchEndAction: fetchEnd,
      fetchStartAction: fetchStart,
      showNotificationAction: showNotification,
    },
  ),
  withStyles(styles),
)(CurrentOrderItems);
